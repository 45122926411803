* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  body{
    background: #000;
  }
  
  .slider {
    margin:0 20px;
    overflow:"hidden";
    padding:2rem 0 0 0;
    /* background: #FCD757; */
  }
  
  .slider img {
    width: 100%;
    border-radius:10px;
  }
  
  /* .react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
  margin: 0 50px;
  margin-bottom: 0px;
  } */
 
  
  .custom-dot-list-style button{
  border: none;
  background: #E6A922;
  }

  .react-multiple-carousel__arrow::before {
    color: #FCD757;
  }
  .react-multi-carousel-dot.react-multi-carousel-dot--active button{
  background: #E6A922 !important;

  }

  .react-multiple-carousel__arrow{
    background: linear-gradient(99.45deg, #312E81 0%, #1E40AF 0.01%, #1E3A8A 46%, #701A75 96.97%)!important;
    border-radius: 50%;
    top: 50%;
  
  }

  @media (max-width: 768px) {
    .react-multiple-carousel__arrow::before {
      font-size: 16px;
    }

    .react-multiple-carousel__arrow{
      min-width: 24px;
      min-height: 24px;

    }

    .react-multiple-carousel__arrow--left {
      left: calc(4% + 12px);
  }
  .react-multiple-carousel__arrow--right {
    right: calc(4% + 12px);
}
   

    
  }

