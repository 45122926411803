.main-container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr; 
    gap: 20px; 
    margin: 0px 10%;
}

.image-container,
.text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative; 
}

.image-container {
    max-width: 320px; 
    width: 100%; 
    height: auto; 
    overflow: hidden; 
    position: relative; 
}

.text-container {
   
    left: -60px; 
    height: calc(100% - 20px); 
    overflow: hidden; 
}

.text-container .text-data {
    overflow: hidden; 
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;
   text-align: justify;
}

@media (min-width: 768px) {
    .main-container {
        grid-template-columns: repeat(2, 1fr); 
    
    }
  
}
@media (max-width: 768px) {
    .main-container {
        gap: 0px !important;

    }
   .text-container {
    left: 0px; 
    border-top-left-radius: 0px !important
    ;
    border-top-right-radius: 0px !important;
    }
}

.image-container {
    max-width: 100%; 
    max-height: 100%; 
}

img {
    max-width: 100%;
    height: auto; 
}
